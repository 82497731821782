<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{ $t("MapView.NewMap", {}, { locale: this.$store.state.activeLang }) }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-map-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="Name" class="form-label required"
          >{{
            $t("MapView.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="mapData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3 mb-3">
      <div class="col col-md-4">
        <label for="SpatialField" class="form-label required">{{
          $t(
            "MapView.SpatialField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeSpatialField"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryMapViewableFields?parentId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="LabelTitle" class="form-label required">{{
          $t("MapView.LabelTitle", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onMultipleSelectChange="onChangeLabelTitle"
          :isMultiple="true"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="DefaultMap" class="form-label required">{{
          $t("MapView.DefaultMap", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :allowEmpty="false"
          :data="defaultMapData"
          @onChange="onChangeForDefaultMap"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewMap",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      mapData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
      },
      isSuccess: false,
      defaultMapData: [
        {
          key: "1",
          value: this.$t(
            "MapView.OpenStreetMap",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "MapView.Google",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "MapView.Atlas",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectValueReset: false,
      nullObject: [{ key: null, value: null }],
    };
  },
  methods: {
    onChangeSpatialField: function (selected) {
      this.mapData.spatialFieldPublicId = selected.key;
    },
    onChangeLabelTitle(ids) {
      this.mapData.titleFieldPublicIds = ids;
    },
    onChangeForDefaultMap: function (selected) {
      this.mapData.defaultMap = selected.key;
    },
    onClear() {
      this.mapData = {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
      };
      $(".form-map-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-map-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-MapViewNew", { ...this.mapData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/Map/" + this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  watch: {
    "$route.params.customObjectId"() {
      this.mapData.customObjectPublicId = this.$route.params.customObjectId;
    },
  },
};
</script>
